import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from './context/auth'
import PropTypes from 'prop-types'

function PrivateRoute({ component: Component, ...rest }) {
  const { authTokens } = useAuth()

  return (
    <Route
      {...rest}
      render={
        (props) =>
          authTokens ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} /> //, state: { referer: props.location }
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.object,
}

export default PrivateRoute
