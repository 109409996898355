import React, { useState } from 'react'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import { AuthContext } from './context/auth'

import './scss/style.scss'

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const Login = React.lazy(() => import('./views/pages/login/Login'))
const SSOVerify = React.lazy(() => import('./views/pages/login/SSOVerify'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function App(props) {
  const storedTokens = localStorage.getItem('tokens')
  var existingTokens
  if (storedTokens && storedTokens !== 'undefined') {
    existingTokens = JSON.parse(storedTokens)
  }
  const [authTokens, setAuthTokens] = useState(existingTokens)

  const setTokens = (data) => {
    localStorage.setItem('tokens', JSON.stringify(data))
    setAuthTokens(data)
  }

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/auth/sso"
              name="SSOVerify"
              render={(props) => <SSOVerify {...props} />}
            />

            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <PrivateRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </AuthContext.Provider>
  )
}

export default App
